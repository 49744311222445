
//myExtObject = function() {
  $(document).ready(function() {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function() {
            window.history.pushState(null, "", window.location.href);
        };
    });
  $(document).on('click', '.jabri-nav-list li', function() {
    $('.jabri-nav-list li').removeClass('active');
    $('.diffnavli').removeClass('openav');
    $('.diffnavli2').removeClass('openav');
    $(this).addClass('active');
  });
  $(document).on('click', '.diffnavli', function() {
    $('.diffnavli.active').addClass('openav');
  });
  $(document).on('click', '.diffnavli2', function() {
    $('.diffnavli2.active').addClass('openav');
  });
  $(document).on('click', '.slide-toggle', function(e) {
    console.log('click*******');
    $(this).removeClass('notoggled');
    $(this).toggleClass('toggled');
    $('.jabri-sidebar').toggleClass('toggled');
    $('.jabri-main-content').toggleClass('toggled');
    $('.jabri-topbar').toggleClass('toggled');
    console.log($('.diffnavli').attr('routerLink'))
    if ($('.diffnavli').attr('routerLink')) {
      $('.diffnavli').show();
      $('.diffnavli1').hide();
      $('.diffnavli').removeAttr('routerLink');
    } else {
      $('.diffnavli').attr('routerLink', '/post-a-job');
      $('.diffnavli').hide();
      $('.diffnavli1').show();
    }
    setTimeout(function() {
      var x = $('.cand-center-inner').width();
      $('.cand-quest-main').css('width', x - 105);
    }, 250);
  });
  $(document).on('click', 'img.toggled', function() {
    $('.diffnavli.active').removeClass('openav');
    setTimeout(function() {
      $('.diffnavli.active').addClass('openav');
    }, 260);
    $('.diffnavli2.active').removeClass('openav');
    setTimeout(function() {
      $('.diffnavli2.active').addClass('openav');
    }, 260);
    setTimeout(function() {
      var x = $('.cand-center-inner').width();
      $('.cand-quest-main').css('width', x - 105);
    }, 250);
  });
  $(document).on('click', '.cand-detail-list-item', function() {
    $('.cand-detail-list-item').removeClass('cand-active');
    $(this).addClass('cand-active');
  });
  $(window).resize(function() {
    var x = $('.cand-center-inner').width();
    $('.cand-quest-main').css('width', x - 105);
  });
  $(document).on('click', '.sign-up-action', function() {
    $(this).addClass("sign-clicked");
    $(".login-parent.candidates").addClass("sign-clicked");
  });
  $(document).on('click', '.filters-btn', function() {
    $(this).toggleClass("filters-open");
    $('.filters-child').toggleClass("filters-open");
  });
  $(document).on('click', '.filters-view', function() {
    $(this).toggleClass("view-switch");
    $('.job-list-parent').toggleClass("view-switch");
  });
  $(document).on('click', '.nav-settings', function() {
    $('.slide-toggle.toggled').trigger('click');
  });
  $(document).on('click', '.nav-postjob', function() {
    $('.slide-toggle.toggled').trigger('click');
  });
  $(document).on('click', '.nav-settings .nav-sub-in', function() {
    $('.nav-settings .nav-sub-in').removeClass('nav-act');
    $(this).addClass('nav-act');
  });
  // $(document).on('mouseleave', '.job-list-tile', function() {
  //     $('.job-list-tile .dropdown.show button').trigger('click');
  // });

  // $(window).scroll(function() {
  //   if ($(this).scrollTop() > ($('.public-view-job .jd-banner ul').offset().top - 42)) {
  //     $('.public-view-job .fixed-title, .fixed-title-action').addClass('fixed');
  //   } else {
  //     $('.public-view-job .fixed-title, .fixed-title-action').removeClass('fixed');
  //   }
  // });

$(window).scroll(function(){
    if ($(this).scrollTop() > 1) {
       $('.navbar').addClass('fixed'); 
       $('.navbar.for-white-header').removeClass('white-header');
    } else {
       $('.navbar').removeClass('fixed'); 
       $('.navbar.for-white-header').addClass('white-header');
    }
});
//};

$(document).on('click', '.postpop-tigger', function() {
      $('.compose-job').css({"display":"none"})
      $('.anime-slide-1').addClass('slide_in');
      $('.anime-slide-1').removeClass('slide_out');
    setTimeout(function(){
      $('.post-job-setting').fadeIn('fast');
      $('.anime-slide-2').addClass('slide_in'); 
      $('.anime-slide-2').removeClass('slide_out');      
    },100);
    setTimeout(function(){
      $('.anime-slide-3').addClass('slide_in');
      $('.anime-slide-3').removeClass('slide_out');      
    },200);
    setTimeout(function(){
      $('.anime-slide-4').addClass('slide_in');
      $('.anime-slide-4').removeClass('slide_out');      
    },300);
    setTimeout(function(){
      $('.anime-slide-5').addClass('slide_in');
      $('.anime-slide-5').removeClass('slide_out');      
    },400);
    setTimeout(function(){
      $('.anime-slide-6').addClass('slide_in');
      $('.anime-slide-6').removeClass('slide_out');      
    },500);
});

// $(document).on('click', '.pset-close a', function() {
//       $('.anime-slide-6').removeClass('slide_in');
//       $('.anime-slide-6').addClass('slide_out');
//     setTimeout(function(){
//       $('.anime-slide-5').removeClass('slide_in'); 
//       $('.anime-slide-5').addClass('slide_out');
//     },50);
//     setTimeout(function(){      
//       $('.anime-slide-4').removeClass('slide_in');
//       $('.anime-slide-4').addClass('slide_out');      
//     },50);
//     setTimeout(function(){
//       $('.anime-slide-3').removeClass('slide_in');
//       $('.anime-slide-3').addClass('slide_out');     
//     },100);
//     setTimeout(function(){
//       $('.anime-slide-2').removeClass('slide_in');
//       $('.anime-slide-2').addClass('slide_out');     
//     },150);
//     setTimeout(function(){
//       $('.anime-slide-1').removeClass('slide_in');
//       $('.anime-slide-1').addClass('slide_out');
//       $('.post-job-setting').fadeOut('fast');
//     },200);
// });

// $(document).on('click', '.jabri-nav-list li', function() {
//     $('.post-job-setting').fadeOut('fast');
// });

$(document).on('click', '.tablist-search .ms-button', function() {
    $('.tablist-search').toggleClass('seaclick');
    $('.tablist-title').toggleClass('seaclick');
    $('.tablist-common .continue-btn').toggleClass('seaclick');
    //$('.tablist-actions + table').toggleClass('seaclick');
    $('.tablist-field input').focus();
});

        $(document).on('click','.jasearch',function(){
            //$(this).toggleClass('seactive');
            //$('.candid_search').toggleClass('seactive');
            //$('.cand-list-parent').toggleClass('seactive');
            //$('.jafilter').removeClass('filtive');
            //$('.cand-list-parent').removeClass('cfactive');
            //$('.candid_filter_tags').removeClass('cfactive');
        });

        // $(document).on('click','.jafilter',function(){
        //     $(this).toggleClass('filtive');
        //     $('.candid_filter_tags').toggleClass('cfactive');
        //     $('.cand-list-parent').toggleClass('cfactive');
        // });


